import { Text as TextDS } from '@cash-me/react-components'
import styled from 'styled-components'

import { Section as GSSection } from 'components/general-styles'
import { theme } from 'styles/theme'

const { media, colors, space, fontSizes, radii } = theme

export const Section = styled(GSSection)`
  @media (${media.tablet}) {
    padding-top: ${space['spacing-xl']};
    padding-bottom: ${space['spacing-xl']};
  }

  @media (${media.desktop}) {
    padding-top: 7.5rem;
  }
`

export const Content = styled.div`
  @media (${media.tablet}) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    gap: ${space['spacing-xxl']};
  }
`

export const ImageWrapper = styled.div`
  position: relative;
  margin-left: -${space['spacing-md']};
  margin-right: -${space['spacing-md']};
  margin-bottom: ${space['spacing-xl']};

  @media (${media.tablet}) {
    width: calc(50% - ${space['spacing-sm']});
    min-width: calc(50% - ${space['spacing-sm']});
    margin: 0;
    border-radius: ${radii['corner-radius-sm']};
    overflow: hidden;
  }
`

export const Infos = styled.div`
  p {
    margin-bottom: ${space['spacing-md']};
  }

  h2 {
    margin-bottom: ${space['spacing-xl']};
  }

  h2 span {
    color: ${colors['pink-text-default']};
  }

  @media (${media.desktop}) {
    h2 {
      margin-bottom: 7.5rem;
      font-size: ${fontSizes['font-size-heading-2']};
    }

    p {
      margin-bottom: ${space['spacing-xl']};
      font-size: ${fontSizes['font-size-body-1']};
    }
  }
`

export const Text = styled(TextDS)`
  @media (${media.desktop}) {
    text-align: center;
    font-size: ${fontSizes['font-size-heading-4']};
  }
`
